import React, {useEffect, useState} from 'react';
import './App.css';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {Route, Link, Switch, withRouter, Redirect} from 'react-router-dom';
import { withAlert } from 'react-alert';
import axios from 'axios';

import Requests from './routes/requests/requests';
import Members from './routes/members/members';
import Drivers from './routes/drivers/drivers';
import Events from './routes/events/events';
import Bodyguards from './routes/bodyguards/bodyguards';
import Venues from './routes/venues/venues';
import Villas from './routes/villas/villas';
import Yachts from './routes/yachts/yachts';
import PricingPlan from './routes/pricingPlan/pricingPlan';
import StripePayment from './routes/stripePayment/stripePayment';
import Page404 from './routes/page404/page404';
import Logout from './routes/logout/logout';
import Profile from './routes/profile/profile';
import Dashboard from './routes/dashboard/dashboard';
import Login from './routes/login/login';
import Request from './routes/request/request';
import Member from './routes/member/member';
import Payment from './routes/payment/payment';
import ResetPassword from './routes/resetPassword/resetPassword';
import AfterStripeOnboardingModal from './components/afterStripeOnboardingModal/afterStripeOnboardingModal';

import TopBar from './components/topBar/topBar';

import * as actions from './store/actions';
//import LockScreen from './routes/lockScreen/lockScreen';

const App = props => {
    const appSettings = useSelector(state => state.settings);
    const auth = useSelector(state => state.auth);
    const toasts = useSelector(state => state.notification.toasts);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (auth.token) {
            dispatch(actions.authInitiateGetUserData());
            //dispatch(actions.initiateGetNotifications());
            dispatch(actions.initiateGetNumberOfNewNotifications());
            updateMenuSize();
        }
    }, [auth.token, dispatch]);

    useEffect(() => {
        if (toasts.length > 0) {
            toasts.forEach(element => {
                switch(element.type) {
                    case "info": props.alert.info(element.message); break;
                    case "success": props.alert.success(element.message); break;
                    case "error": props.alert.error(element.message); break;
                }
            });
            dispatch(actions.clearToasts());
        }
    }, [toasts]);

    useEffect(() => {
        const name = localStorage.getItem('name');
        const email = localStorage.getItem('email');
        const isIntercomOpen = localStorage.getItem('intercomOpen');
        if (isIntercomOpen) {
            return () => localStorage.removeItem('intercomOpen');
        }
        if (!email && !name ) {
            return () => localStorage.removeItem('intercomOpen');
        }
        localStorage.setItem('intercomOpen', true);
        window.intercomSettings = { ...window.intercomSettings, name, email, "hide_default_launcher": false};
        window.Intercom('update', window.intercomSettings);
    })

    useEffect(() => {
        updateMenuSize();
    }, [appSettings.enlargedMenu]);

    const updateMenuSize = () => {
        window.addEventListener('resize', () => {
            if (window.innerWidth < 1150 && !document.body.classList.contains('enlarged')) {
                document.body.classList.add('enlarged');
            } else if (window.innerWidth > 1150 && document.body.classList.contains('enlarged')) {
                document.body.classList.remove('enlarged');
            }
        });
    };

    useEffect(() => {
        let queryParams = new URLSearchParams(props.location.search);
        if (queryParams.get('code')) {
            const data = new FormData();
            data.append("authorization_code", queryParams.get('code'));

            setIsModalOpen(true);
            const response = axios.post(process.env.REACT_APP_API_URL + '/users/accounts', data, {
                headers: {
                    "Accept": 'application/json',
                    'auth-token': auth.token
                }
            })
                .then(function (response) {
                    setIsModalOpen(true);
                })
                .catch(function (response) {
                    //dispatch(actions.addToasts([{message:"Couldn't connect!", type:"success"}]));
                });
        }
    }, [props.location.search]);

    const closeModal = () => { setIsModalOpen(false) }
    const afterCloseModal = () => { }

    const Panel = () => {
        return (
            <div id='wrapper' className='AdminApp'>
                <Route path='/' render={props => <TopBar />} />

                <div id='menu'>
                    <div className='left side-menu'>
                        <div className='slimscroll-menu' id='remove-scroll'>
                            <div id='sidebar-menu'>
                                <ul className='metismenu in' id='side-menu'>

                                    <li>
                                        <Link
                                            to='/'
                                            className={
                                                props.location.pathname === '/' ? 'active' : null
                                            }
                                        >
                                            <i className='icon-dashboard'></i>
                                            <span className='menu-item-label'>BESEATED</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/requests'
                                            className={
                                                props.location.pathname === '/requests'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='fa fa-list-alt'></i>
                                            <span className='menu-item-label'>REQUESTS</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/members'
                                            className={
                                                props.location.pathname === '/members'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='icon-clients'></i>
                                            <span className='menu-item-label'>CLIENTS</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/concierge/drivers'
                                            className={
                                                props.location.pathname === '/concierge/drivers'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='icon-driver'></i>
                                            <span className='menu-item-label'>DRIVERS</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/concierge/events'
                                            className={
                                                props.location.pathname === '/concierge/events'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='icon-ticket'>{/*fa fa-ticket*/}</i>
                                            <span className='menu-item-label'>EVENTS</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/concierge/bodyguards'
                                            className={
                                                props.location.pathname === '/concierge/bodyguards'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='icon-bodyguards'></i>
                                            <span className='menu-item-label'>GUARDS</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/concierge/venues'
                                            className={
                                                props.location.pathname === '/concierge/venues'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='icon-venue'></i>
                                            <span className='menu-item-label'>VENUES</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/concierge/yachts'
                                            className={
                                                props.location.pathname === '/concierge/yachts'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='icon-yachts'></i>
                                            <span className='menu-item-label'>YACHTS</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link
                                            to='/concierge/villas'
                                            className={
                                                props.location.pathname === '/concierge/villas'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='fa fa-home'></i>
                                            <span className='menu-item-label'>VILLAS</span>
                                        </Link>
                                    </li>

                                    <li>
                                        <a target='_blank' href='https://www.booking.com/index.html?aid=2068170'>
                                            <i className='fa fa-building'></i>
                                            <span className='menu-item-label'>HOTELS</span>
                                        </a>
                                    </li>

                                    <li>
                                        <Link
                                            to='/stripePayment'
                                            className={
                                                props.location.pathname === '/stripePayment'
                                                    ? 'active'
                                                    : null
                                            }
                                        >
                                            <i className='fa fa-dollar'></i>
                                            <span className='menu-item-label'>PAYMENTS</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='clearfix'></div>
                        </div>
                    </div>
                </div>
                <div id='content-wrapper'>
                    <Route path='/' exact component={Dashboard} />
                    <Route path='/profile' component={Profile} />
                    <Route path='/concierge/drivers' component={Drivers} />
                    <Route path='/concierge/events' component={Events} />
                    <Route path='/concierge/bodyguards' component={Bodyguards} />
                    <Route path='/concierge/venues' component={Venues} />
                    <Route path='/concierge/villas' component={Villas} />
                    <Route path='/concierge/yachts' component={Yachts} />
                    <Route path='/payment' component={Payment} />
                    <Route path='/stripePayment' component={StripePayment} />
                    <Route path='/pricing' component={PricingPlan} />
                    <Route path='/page404' component={Page404} />
                    {/* <Route path='/lockScreen' component={LockScreen} /> */}
                    <Switch>
                        <Route path='/requests/:requestId' component={Request} />
                        <Route path='/members/:memberId' component={Member} />
                        <Route path='/requests' component={Requests} />
                        <Route path='/members' component={Members} />
                    </Switch>
                    <AfterStripeOnboardingModal closeModal={closeModal} afterCloseModal={afterCloseModal} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen}/>
                </div>
            </div>
        );
    };

    const Render = () => {
        if (auth.logout) {
            return <Logout />;
        }
        if (auth.token) {
            return (
                <Switch>
                    <Redirect from='/changePassword' to='/'></Redirect>
                    <Panel />;
                </Switch>
            );
        }
        return (
            <React.Fragment>
                <Route path='/' exact component={Login} />
                <Route path='/changePassword' component={ResetPassword} />
            </React.Fragment>
        );
    };

    return <Render />;
};

export default (withRouter(withAlert()(App)));
