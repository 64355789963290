import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

//stripe provider
import { StripeProvider } from 'react-stripe-elements';

//router
import { ConnectedRouter as Router, connectRouter } from "connected-react-router";
import { routerMiddleware } from 'react-router-redux'
import { createBrowserHistory } from 'history';
import ScrollToTop from './utils/scrollToTop';

//redux+saga
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import authReducer from './store/reducers/auth';
import settingsReducer from './store/reducers/settings';
import requestReducer from './store/reducers/request';
import memberReducer from './store/reducers/member';
import locationReducer from './store/reducers/location';
import luxuryReducer from './store/reducers/luxury';
import conciergeReducer from './store/reducers/concierge';
import managerReducer from './store/reducers/manager';
import amenityReducer from './store/reducers/amenity';
import paymentReducer from './store/reducers/payment';
import notificationReducer from './store/reducers/notification';
import watchAll from './store/sagas';

//alerts
import { transitions, positions, Provider as AlertProvider } from 'react-alert';
import AlertTemplate from 'react-alert-template-basic';
const alertOptions = {
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE,
    containerStyle: {
        zIndex: 1000,
        marginTop: '100px'
    }
}

const AlertTemplateBeseated = ({ style, options, message, close }) => {
    const getBackgroundColor = () => {
        switch(options.type) {
            case 'info': return 'rgba(30, 144, 255, 0.95)';
            case 'success': return 'rgba(50, 205, 50, 0.95)';
            case 'error': return 'rgba(220, 20, 60, 0.95)';
        }
    }

    const getBorderColor = () => {
        switch(options.type) {
            case 'info': return '#1919ff';
            case 'success': return '#006400';
            case 'error': return '#8B0000';
        }
    }

    const alertStyle = {
        backgroundColor: getBackgroundColor(),
        color: 'black',
        padding: '10px',
        borderRadius: '3px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxShadow: '0px 2px 2px 2px rgba(0, 0, 0, 0.03)',
        fontFamily: 'Arial',
        width: '300px',
        boxSizing: 'border-box',
        border: '1px solid ' + getBorderColor()
    }

    return(
        <div style={{...style, ...alertStyle}}>
            {message}
            <a className="close" onClick={close}></a>
        </div>);
}

const composeEnhancers =
    process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        : null || compose;

const history = createBrowserHistory();
const rootReducer = combineReducers({
    auth: authReducer,
    settings: settingsReducer,
    request: requestReducer,
    member: memberReducer,
    location: locationReducer,
    luxury: luxuryReducer,
    concierge: conciergeReducer,
    manager: managerReducer,
    amenity: amenityReducer,
    payment: paymentReducer,
    notification: notificationReducer,
    router: connectRouter(history)
});

const sagaMiddleware = createSagaMiddleware();
const routMiddleware = routerMiddleware(history);

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(
        sagaMiddleware,
        routMiddleware
    ))
);
sagaMiddleware.run(watchAll);

const app = (
    <AlertProvider template={AlertTemplateBeseated} {...alertOptions}>
        <Provider store={store}>
            <Router history={history}>
                <StripeProvider apiKey="pk_live_v56aqnReyMU2UzesCm5MSR7s">
                    <ScrollToTop>
                        <App />
                    </ScrollToTop>
                </StripeProvider>
            </Router>
        </Provider>
    </AlertProvider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
